@media (max-width: 767px) {

  /* Force table to not be like tables anymore */
  table.responsive,
  table.responsive thead,
  table.responsive tbody,
  table.responsive th,
  table.responsive td,
  table.responsive tr {
    display: block;
  }

  table.responsive table {
    border: none;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  table.responsive thead tr,
  table.responsive tfoot tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  table.responsive tr {
    margin-bottom: 15px;
    border: 1px solid #dddddd !important;
  }

  table.responsive .responsive-label {
    width: 40%;
    display: inline-block;
    font-weight: 500;
    left: 10px;
    position: absolute;
  }

  table.responsive > tbody > tr:not(.row-no-header) > td:not([colspan]) {
    padding-left: 40%;
  }

  table.responsive > tbody > tr > td {
    /* Behave  like a "row" */
    border: none !important;
    position: relative;
    margin-bottom: -1px;
    border-bottom: 1px solid #dddddd !important;
  }

  table.responsive td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }
}