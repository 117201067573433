.cursor-pointer {
  cursor: pointer;
}

.alert {
  font-size: 14px;
  border-radius: 0;
}

.breadcrumb {
  background: transparent;
  padding: 5px 0;
  font-size: 0.9rem;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.badge {
  border-radius: 3px;
  padding: 0.3em 0.5em;
  font-weight: 500;
}

.dropdown-menu {
  box-shadow: 0 0 19px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(0, 0, 0, 0.07);
}

.font-weight-bold {
  font-weight: 500 !important;
}

.table td, .table th {
  border-top: 1px solid #ededed;
}

.text-light-gray {
  color: #a0a0a1 !important;
}

html {
  position: relative;
  min-height: 100%;
}

body {
  font-family: "Roboto", sans-serif;
  color: #3e3d4d;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
}

a {
  transition: color 300ms;
}

table tr td, table tr th {
  vertical-align: middle !important;
}

th {
  font-weight: 500;
}

b, strong {
  font-weight: 500;
}

.has-sticky-footer {
  margin-bottom: 80px; /* Margin bottom by footer height */
}

.sticky-footer {
  background-color: #f5f5f5;
  position: absolute;
  width: 100%;
  bottom: 0;
}

.wrapper {
  padding-top: 56px;
}
@media (min-width: 576px) {
  .wrapper {
    padding-top: 72px;
  }
}

.content-wrapper {
  padding-top: 15px;
  padding-bottom: 20px;
}
@media (min-width: 576px) {
  .content-wrapper {
    padding-top: 30px;
  }
}

.navbar-light .navbar-brand {
  font-weight: 500;
  color: #3e3d4d;
}

.navbar .navbar-nav .dropdown-menu {
  position: absolute;
}

label {
  font-weight: 500;
}

.form-plaintext .form-group {
  margin-bottom: 0;
}
@media (max-width: 575px) {
  .form-plaintext .form-group label {
    padding-bottom: 0;
  }
}

@media (max-width: 575px) {
  .form-control-plaintext {
    padding-top: 0;
  }
}

.custom-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23aaaaaa' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  font-size: 0.9rem;
}

.form-text {
  font-size: 0.75rem;
  color: #a0a0a1;
  margin-top: 0.3rem;
}

.text-error {
  margin-top: 0.25rem;
  margin-bottom: 0;
  font-size: 0.9rem;
}

.text-error + .text-error {
  margin-top: 0;
}

::placeholder {
  font-size: 0.9rem;
  color: #939394 !important;
}

.form-control:disabled, .form-control[readonly] {
  background-color: whitesmoke;
}

.form-section-title {
  font-weight: 500;
  letter-spacing: 4px;
  text-transform: uppercase;
  font-size: 0.9rem;
  color: #a0a0a1;
}

.form-group + .form-section-title {
  margin-top: 35px;
}

.row + .form-section-title {
  margin-top: 20px;
}

.form-group .file-upload-default {
  visibility: hidden;
  position: absolute;
  left: -9999px;
}

.form-group .file-upload-info {
  background: transparent;
}

.icons-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  cursor: pointer;
  height: 300px;
  overflow-y: auto;
  border: 1px solid rgba(0, 0, 0, 0.07);
  box-shadow: inset 0 11px 8px -10px #ddd, inset 0 -11px 8px -10px #ddd;
  padding: 10px;
}
.icons-wrapper > div {
  display: flex;
  align-items: center;
  align-self: baseline;
  margin-bottom: 10px;
  width: 33%;
}
.icons-wrapper > div:hover, .icons-wrapper > div.active {
  color: #00abec;
}
.icons-wrapper i {
  margin-right: 10px;
  font-size: 2rem;
  line-height: 1;
}
.icons-wrapper span {
  vertical-align: middle;
}

.pick-icon-search {
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-radius: 0;
}

.form-auth {
  width: 100%;
  max-width: 330px;
  padding: 30px 5px;
  margin: auto;
}

.auth-control {
  font-size: 0.9rem;
}

@media (max-width: 767px) {
  /* Force table to not be like tables anymore */
  table.responsive,
  table.responsive thead,
  table.responsive tbody,
  table.responsive th,
  table.responsive td,
  table.responsive tr {
    display: block;
  }
  table.responsive table {
    border: none;
  }
  /* Hide table headers (but not display: none;, for accessibility) */
  table.responsive thead tr,
  table.responsive tfoot tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  table.responsive tr {
    margin-bottom: 15px;
    border: 1px solid #dddddd !important;
  }
  table.responsive .responsive-label {
    width: 40%;
    display: inline-block;
    font-weight: 500;
    left: 10px;
    position: absolute;
  }
  table.responsive > tbody > tr:not(.row-no-header) > td:not([colspan]) {
    padding-left: 40%;
  }
  table.responsive > tbody > tr > td {
    /* Behave  like a "row" */
    border: none !important;
    position: relative;
    margin-bottom: -1px;
    border-bottom: 1px solid #dddddd !important;
  }
  table.responsive td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }
}
.app-banner {
  background: #3e3d4d;
  padding-top: 25px;
  color: #ffffff;
}
.app-banner .btn-section-wrapper {
  margin-top: 30px;
}
.app-banner .banner-title {
  font-size: 2rem;
}
@media (max-width: 575px) {
  .app-banner .banner-title {
    font-size: 1.2rem;
  }
}
.app-banner .btn-section {
  background: #ffffff;
  color: #3e3d4d;
  border-radius: 0;
  padding: 0.375rem 1.5rem;
}
@media (max-width: 575px) {
  .app-banner .btn-section {
    font-size: 0.9rem;
    padding: 0.3rem 1rem;
    background: transparent;
    color: #ffffff;
  }
  .app-banner .btn-section.active {
    background: #ffffff !important;
    color: #3e3d4d !important;
  }
  .app-banner .btn-section:hover:not(.active) {
    background: #ffffff;
    color: #20c2ff;
  }
}
.app-banner .btn-section.active {
  background: #00abec;
  color: #ffffff;
}
.app-banner .btn-section:hover:not(.active) {
  background: #20c2ff;
  color: #ffffff;
}

.app-banner-description {
  padding: 1rem 0;
  background: #00abec;
  color: #ffffff;
}

.app-wrapper {
  padding-top: 30px;
  padding-bottom: 30px;
}
@media (min-width: 576px) {
  .app-wrapper {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@media (min-width: 576px) {
  .card-app {
    padding-right: 30px;
    padding-left: 30px;
  }
}
.card-app a {
  color: #3e3d4d;
}
.card-app a:hover {
  text-decoration: none;
}
.card-app a:hover .card:after {
  background: rgba(0, 0, 0, 0.1);
  bottom: -10px;
}
.card-app a:hover .app-title {
  color: #101014;
}
.card-app a:hover .app-subtitle {
  color: #545454;
}
@media (min-width: 576px) {
  .card-app a:hover .app-icon {
    font-size: 7rem;
  }
}
.card-app .app-icon {
  transition: all 100ms;
  font-size: 3rem;
  line-height: 1;
}
@media (min-width: 576px) {
  .card-app .app-icon {
    font-size: 6.6rem;
  }
}
.card-app .card {
  position: relative;
  border: none;
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.15);
  overflow: hidden;
  min-width: 92px;
}
@media (min-width: 576px) {
  .card-app .card {
    min-width: auto;
    min-height: 200px;
  }
}
.card-app .card:after {
  transition: all 100ms;
  content: "";
  position: absolute;
  bottom: -20px;
  height: 35px;
  width: 100%;
  background: rgba(0, 0, 0, 0.05);
  transform: rotate(-5deg);
}
.card-app .card-title {
  margin-bottom: 0;
}
@media (min-width: 576px) {
  .card-app .card-title {
    margin-top: 20px;
  }
}
.card-app .card-title .app-title {
  transition: all 100ms;
  font-size: 1rem;
  margin-bottom: 5px;
}
.card-app .card-title .app-subtitle {
  transition: all 100ms;
  font-size: 0.9rem;
  color: #a0a0a1;
}
.card-app .app-version {
  font-size: 0.75rem;
  color: #a2a1b5;
}
.card-app .app-version a:hover {
  color: #00abec;
}