label {
  font-weight: $weight-bold;
}

.form-plaintext .form-group {
  margin-bottom: 0;

  @media (max-width: 575px) {
    label {
      padding-bottom: 0;
    }
  }
}

.form-control-plaintext {
  @media (max-width: 575px) {
    padding-top: 0;
  }
}

.custom-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23aaaaaa' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  font-size: 0.9rem;
}

.form-text {
  font-size: 0.75rem;
  color: $light-gray;
  margin-top: 0.3rem;
}

.text-error {
  margin-top: .25rem;
  margin-bottom: 0;
  font-size: 0.9rem;
}

.text-error + .text-error {
  margin-top: 0;
}

::placeholder {
  font-size: 0.9rem;
  color: darken($light-gray, 5%) !important;
}

.form-control:disabled, .form-control[readonly] {
  background-color: lighten($light-gray, 33%);
}

.form-section-title {
  font-weight: $weight-bold;
  letter-spacing: 4px;
  text-transform: uppercase;
  font-size: 0.9rem;
  color: $light-gray;
}

.form-group + .form-section-title {
  margin-top: 35px;
}

.row + .form-section-title {
  margin-top: 20px;
}

.form-group .file-upload-default {
  visibility: hidden;
  position: absolute;
  left: -9999px;
}

.form-group .file-upload-info {
  background: transparent;
}

.icons-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  cursor: pointer;
  height: 300px;
  overflow-y: auto;
  border: 1px solid rgba(0, 0, 0, 0.07);
  box-shadow: inset 0 11px 8px -10px #ddd, inset 0 -11px 8px -10px #ddd;
  padding: 10px;

  > div {
    display: flex;
    align-items: center;
    align-self: baseline;
    margin-bottom: 10px;
    width: 33%;

    &:hover, &.active {
      color: $primary;
    }
  }

  i {
    margin-right: 10px;
    font-size: 2rem;
    line-height: 1;
  }

  span {
    vertical-align: middle;
  }
}

.pick-icon-search {
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-radius: 0;
}