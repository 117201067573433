.cursor-pointer {
  cursor: pointer;
}

.alert {
  font-size: 14px;
  border-radius: 0;
}

.breadcrumb {
  background: transparent;
  padding: 5px 0;
  font-size: 0.9rem;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.badge {
  border-radius: 3px;
  padding: .3em .5em;
  font-weight: $weight-bold;
}

.dropdown-menu {
  box-shadow: 0 0 19px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(0, 0, 0, .07);
}

.font-weight-bold {
  font-weight: $weight-bold !important;
}

.table td, .table th {
  border-top: 1px solid lighten($light-gray, 30%);
}

.text-light-gray {
  color: $light-gray !important;
}